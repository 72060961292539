import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueI18n from "@/libs/i18n";

export default function useAnimalList() {
  // Use toast
  const toast = useToast();

  const refAnimalListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { label: VueI18n.t("earingno"), key: "earingno", sortable: true },
    // { key: "animalName", label: VueI18n.t("animalName"), sortable: true },
    // {
    //   key: "animalType",
    //   label: VueI18n.t("animalType"),
    //   sortable: true,
    // },
    // { label: VueI18n.t("genderName"), key: "gendername", sortable: false },
    // { label: VueI18n.t("statusName"), key: "statusname", sortable: false },
    // { label: VueI18n.t("groupName"), key: "groupName", sortable: false },
    // { label: VueI18n.t("rfid"), key: "rfidno", sortable: false },
    // { label: VueI18n.t("birthdate"), key: "birthdate", sortable: false },
    // { key: "animalMilking", label: VueI18n.t("animalMilking") },
    // { key: "animalFeeds", label: VueI18n.t("animalFeeds") },
    // { key: "edit", label: VueI18n.t("edit") },
    // { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const genderFilter = ref(null);
  const sourceFilter = ref(null);
  const statusFilter = ref(null);
  const groupFilter = ref(null);
  const barnFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAnimalListTable.value
      ? refAnimalListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAnimals.value,
    };
  });

  const totalAnimals = computed(() => {
    try {
      return animals.value[0] ? animals.value[0].totalAnimalNumber : 0;
    } catch (error) {
      return 0;
    }
  });

  const animals = computed(() => {
    try {
      return store.state.animalsModule.animals.sort((a, b) => {
        if (isSortDirDesc.value) {
          return b[sortBy.value] > a[sortBy.value] ? 1 : -1;
        } else {
          return a[sortBy.value] > b[sortBy.value] ? 1 : -1;
        }
      });
    } catch (error) {
      return [];
    }
  });

  const refetchData = () => {
    fetchAnimals();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      sourceFilter,
      barnFilter,
    ],
    () => {
      store.commit("animalsModule/SET_CURRENT_PAGE", currentPage.value);
      store.commit("animalsModule/SET_PER_PAGE", perPage.value);

      refetchData();
    }
  );

  const deleteAnimal = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimal", { id: ctx })
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: "Animal deleted",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error delete animal",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchAnimals = async (ctx, callback) => {
    var animals = await store
      .dispatch("animalsModule/fetchAnimals", {
        q: searchQuery.value ?? "",
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
        gender: genderFilter.value,
        status: statusFilter.value,
        sourceid: sourceFilter.value,
        groupid: groupFilter.value,
        plantid: store.state.app.selectedPlantId,
        padokid: barnFilter.value,
      })

      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("animals") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return animals;
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAnimals,
    tableColumns,
    perPage,
    currentPage,
    totalAnimals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAnimalListTable,
    deleteAnimal,
    animals,

    refetchData,

    // Extra Filters
    sourceFilter,
    typeFilter,
    genderFilter,
    statusFilter,
    groupFilter,
    barnFilter,
  };
}
