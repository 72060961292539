<template>
  <div class="w-100">
    <div
      class="d-flex justify-content-between align-items-center py-2 px-2 flex-wrap border-bottom"
    >
      <div class="d-flex align-items-center flex-wrap wrapper">
        <transition name="expand">
          <b-link
            v-if="isMainPage"
            :to="{
              path: '/animals/actions',
              query: {
                id: this.$store.state.animalsModule.selectedAnimal.id,
              },
            }"
          >
            <div
              class="d-flex align-items-center justify-content-center backIcon border-right flex-wrap"
            >
              <div class="d-flex">
                <feather-icon icon="ArrowLeftIcon" size="25" />
                <span class="ml-1 mr-2">{{ $t("actions") }}</span>
                <p />
              </div>
            </div>
          </b-link>
        </transition>
        <div :class="{ 'ml-1': isMainPage }" class="">
          <h3 class="m-0 text-center">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1 mt-sm-0 ml-auto">
        <portal-target name="animal-actions-button" />
      </div>
    </div>

    <div class="p-2">
      <transition name="fade" mode="out-in">
        <router-view name="nestedView" />
      </transition>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$t(this.$route.meta.title);
    },
    isMainPage() {
      return this.$route.name !== "animal-actions";
    },
  },
};
</script>
<style lang="scss">
.backIcon {
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;

  & > div {
    position: relative;

    & > p {
      position: absolute;
      border-bottom: 1px solid #fe671d;
      width: 0;
      bottom: 0;
      margin-bottom: -4px;
      transition: all 200ms ease-out;
    }
  }

  div > span {
    font-size: 16px;
    font-weight: 600;
    color: rgb(30, 28, 28);

    .dark-layout & {
      color: rgb(255, 255, 255);
    }
  }

  &:hover {
    cursor: pointer;
    color: rgb(30, 28, 28);

    .dark-layout & {
      color: rgb(255, 255, 255);
    }

    & > div > p {
      border-bottom: 1px solid rgb(30, 28, 28);
      width: 100%;

      .dark-layout & {
        border-bottom: 1px solid rgb(255, 255, 255);
      }
    }
  }
}

.expand-enter-active {
  transition: all 1000ms ease;
  max-width: 100%;
}
.expand-leave-active {
  transition: all 600ms ease;
  max-width: 100%;
}
.expand-enter,
.expand-leave-to {
  max-width: 0;
  overflow: hidden;
}

.wrapper {
  margin-top: -12px;

  & > * {
    margin-top: 12px;
  }
}
</style>
