<template>
  <div>
    <animal-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewAnimalSidebarActive"
      :animal-types="typeOptions"
      :animal-sources="sourceOptions"
      :animal-breeds="breedOptions"
      :gender-options="genderOptions"
      :animal-status="statusOptions"
      :animal-groups="groupOptions"
      @refetch-group="refetchGroup"
      @refetch-barns="refetchBarn"
      @refetch-data="refetchData"
    />
    <animal-list-edit
      :key="JSON.stringify(selectedAnimal)"
      :is-add-new-user-sidebar-active="isEditAnimalSidebarActive"
      :animal-types="typeOptions"
      :animal-sources="sourceOptions"
      :animal-breeds="breedOptions"
      :gender-options="genderOptions"
      :animal-status="statusOptions"
      :animal-groups="groupOptions"
      :selected-animal="selectedAnimal"
      @refetch-group="refetchGroup"
      @refetch-data="refetchData"
    />

    <b-row>
      <b-col cols="12">
        <animal-list-filters
          :type-filter.sync="typeFilter"
          :type-options="typeOptions"
          :gender-filter.sync="genderFilter"
          :gender-options="genderOptions"
          :source-filter.sync="sourceFilter"
          :source-options="sourceOptions"
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
          :group-filter.sync="groupFilter"
          :group-options="groupOptions"
          :barn-filter.sync="barnFilter"
          :barn-options="barnOptions"
        />
      </b-col>

      <b-col class="animal-table-col">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col class="" cols="12" md="12">
                <div class="d-flex flex-wrap align-items-center">
                  <div class="d-flex justify-content-between w-100">
                    <b-button
                      variant="primary"
                      @click="isAddNewAnimalSidebarActive = true"
                    >
                      <span class="text-nowrap">{{ $t("addAnimal") }}</span>
                    </b-button>
                    <b-button
                      v-b-modal.animal-filter-modal
                      variant="outline-primary"
                      size="sm"
                    >
                      <feather-icon icon="FilterIcon" />
                      {{ filterCount }}
                    </b-button>
                  </div>
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block my-2"
                    :placeholder="$t('search')"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refAnimalListTable"
            class="position-relative overflow-auto"
            :items="animals"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            select-mode="single"
            :empty-text="$t('noRecords')"
            :sort-desc.sync="isSortDirDesc"
            tbody-tr-class="rowClass"
            :selectable="true"
            @row-clicked="(item) => selectAnimal(item)"
          >
            <template #cell(earingno)="data">
              {{ data.item.earingno }}
            </template>
            <template #cell(delete)="data">
              <b-link class="d-flex" @click="deleteAnimal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <small class="align-middle ml-50 text-dark">Delete</small>
              </b-link>
            </template>
            <template #cell(edit)="data">
              <b-link class="d-flex" @click="editAnimal(data.item)">
                <feather-icon icon="EditIcon" />
                <small class="align-middle ml-50 text-dark">Edit</small>
              </b-link>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-end justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalAnimals"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                class="mt-2 d-flex align-items-end justify-content-end justify-content-sm-start"
              >
                <span class="text-muted text-right">
                  {{ dataMeta.from }} / {{ dataMeta.to }} - {{ dataMeta.of }}
                  {{ $t("entries") }}</span
                >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col class="slot">
        <b-row
          class="flex-grow-1 mb-16"
          style="padding-left: 14px; padding-right: 14px"
        >
          <b-col class="p-0 d-flex" style="" cols="12">
            <b-card
              id="animal-detail-card"
              no-body
              class="m-0 mt-2 mt-md-0 d-flex flex-grow-1 w-100"
            >
              <slot name="animal-detail" />
            </b-card>
          </b-col>
          <b-col
            class="p-0 flex-grow-1 d-flex"
            :style="
              $store.state.app.windowWidth > 768
                ? 'height: calc(100% - ' + detailInfoHeight + 'px)'
                : ''
            "
          >
            <b-card
              id="animal-detail-card-actions"
              no-body
              class="m-0 flex-grow-1 mt-2"
              :style="
                $store.state.app.windowWidth > 768
                  ? 'height: calc(100% - ' + detailInfoHeight + 'px)'
                  : ''
              "
            >
              <slot :refetch-data="fetchAnimals" />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref, computed } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useAnimalList from "../../useAnimalList";
import AnimalListAddNew from "./AnimalListAddNew.vue";
import AnimalListEdit from "./AnimalListEdit.vue";
import AnimalListFilters from "./AnimalListFilters.vue";
import { mapState } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    AnimalListAddNew,
    AnimalListEdit,
    AnimalListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    FeatherIcon,

    // vSelect,
  },
  data() {
    return {
      detailsRow: null,
      selectedAnimal: {},
      typeOptions: [],
      sourceOptions: [],
      breedOptions: [],
      statusOptions: [],
      groupOptions: [],
      barnOptions: [],
      detailInfoHeight: 72,
      genderOptions: [
        { label: this.$t("none"), value: null },
        { label: this.$t("female"), value: 1 },
        { label: this.$t("male"), value: 2 },
      ],
    };
  },
  computed: {
    ...mapState({
      isEditAnimalSidebarActive: (state) =>
        state.animalsModule.isEditAnimalSidebarActive,
      animal: (state) => state.animalsModule.selectedAnimal,
    }),
  },

  watch: {
    $route: {
      async handler() {
        setTimeout(() => {
          this.scrollToMain();
          this.calcHeight();
        }, 1);
      },
      deep: true,
    },
    animal: {
      async handler(newVal) {
        this.selectedAnimal = newVal;
      },
      deep: true,
    },
    selectedAnimal: {
      async handler(newVal) {
        this.$store.commit("animalsModule/SET_ANIMAL", newVal);
        const { href } = this.$router.resolve({
          ...this.$router.currentRoute,
          query: { id: newVal.id },
        });
        window.history.pushState({}, null, href);

        this.scrollToMain();
        this.calcHeight();
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener("resize", () => this.calcHeight());
  },
  async created() {
    this.$root.$on("refetch-data", this.fetchAnimals);

    this.typeOptions = [{ label: this.$t("none"), value: null }];
    this.sourceOptions = [{ label: this.$t("none"), value: null }];
    this.breedOptions = [{ label: this.$t("none"), value: null }];
    this.statusOptions = [{ label: this.$t("none"), value: null }];
    var animalProperties = {};

    const [types, sources, breeds, statuses] = await Promise.allSettled([
      this.$http.get("/Animals/GetAnimalTypes"),
      this.$http.get("/Animals/GetAnimalSources"),
      this.$http.get("/Animals/GetAnimalBreeds"),
      this.$store.dispatch("definitionsModule/fetchStatuses"),
    ]);
    if (types.status === "fulfilled")
      types.value.data.map((x) =>
        this.typeOptions.push({ label: x.name, value: x.id })
      );
    if (sources.status === "fulfilled")
      sources.value.data.map((x) =>
        this.sourceOptions.push({ label: x.name, value: x.id })
      );
    if (breeds.status === "fulfilled")
      breeds.value.data.map((x) =>
        this.breedOptions.push({ label: x.name, value: x.id })
      );
    if (statuses.status === "fulfilled")
      statuses.value.map((x) =>
        this.statusOptions.push({ label: x.name, value: x.id })
      );
    animalProperties = {
      type: this.typeOptions,
      source: this.sourceOptions,
      breed: this.breedOptions,
      status: this.statusOptions,
    };
    this.$store.commit("animalsModule/SET_ANIMALPROPERTIES", animalProperties);
    this.refetchGroup();
    this.refetchBarn();
    const fetchAnimals = async (page) => {
      await this.$store.dispatch("animalsModule/fetchAnimals", {
        page: page,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        type: null,
        gender: null,
        status: null,
        sourceid: null,
        groupid: null,
        plantid: this.$store.state.app.selectedPlantId,
        padokid: null,
      });
    };
    await fetchAnimals(1);
    var foundedIndex = 0;
    // TODO: Burası çok karışık düzelt.
    /// Eğer url'de id parametresi varsa, o id'ye ait bir hayvanı seçer.
    /// Bunu bütün sayfaları tek tek gezerek buluyoruz.
    /// Daha iyi bir yöntem bulabiliriz.
    if (
      this.$store.state.animalsModule.selectedAnimal.id != null ||
      this.$route.query.id != null
    ) {
      if (this.$route.query.id != null) {
        let page = 1;

        while (this.selectedAnimal.id != this.$route.query.id) {
          foundedIndex = this.animals.findIndex(
            (x) => x.id == this.$route.query.id
          );
          var founded = this.animals[foundedIndex];

          if (founded == undefined && page < this.totalAnimals / this.perPage) {
            page++;
            await fetchAnimals(page);
          } else {
            if (founded == undefined) {
              page = 1;
              await fetchAnimals(page);
              this.selectedAnimal = {
                ...this.animals[0],
              };
              this.currentPage = 1;
            } else {
              this.selectedAnimal = { ...founded };
              this.currentPage = page;
            }
          }
        }
      }
      setTimeout(() => {
        this.$refs.refAnimalListTable.selectRow(foundedIndex);
      }, 1500);
    } else {
      this.selectedAnimal = this.animals[0];
      this.$refs.refAnimalListTable.selectRow(0);
    }
    if (this.$route.query.id != this.selectedAnimal.id) {
      // await this.$router.push({
      //   path: `/animals/actions?id=${this.selectedAnimal.id}`,
      // });
    }
  },

  methods: {
    scrollToMain() {
      const element = document.getElementById("animal-detail-card");
      if (!!element) {
        var headerOffset = 110;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        // window.scrollTo({
        //   top: offsetPosition,
        //   behavior: "auto",
        // });
      }
    },
    calcHeight() {
      this.detailInfoHeight =
        document.querySelector("#animal-detail-card").getBoundingClientRect()
          .height - 48;
    },

    async refetchGroup() {
      this.groupOptions = [];
      var data = await this.$store.dispatch("animalsModule/fetchGroups");
      this.groupOptions = [
        { label: this.$t("none"), value: null },
        ...data.map((x) => ({
          label: x.groupname,
          value: x.id,
        })),
      ];
    },
    async refetchBarn() {
      this.barnOptions = [];
      var data = await this.$store.dispatch("definitionsModule/fetchPadoks", {
        plantid: this.$store.state.app.selectedPlantId,
        barnid: -1,
      });
      this.barnOptions = [
        { label: this.$t("none"), value: null },
        ...data.map((x) => ({
          label: x.padokname,
          value: x.id,
        })),
      ];
    },
    editAnimal(animal) {
      this.selectedAnimal = { ...animal };
      this.selectedAnimal;
    },
    addAnimalMilking(animal) {
      this.selectedAnimal = { ...animal };
      this.isAddNewAnimalMilkingSidebarActive = true;
    },
    editAnimalMilking(animal) {
      this.selectedAnimal = { ...animal };
      this.isEditAnimalMilkingSidebarActive = true;
    },
    async selectAnimal(animal) {
      this.selectedAnimal = { ...animal };
    },
    async fetchAnimalExams(id) {
      let exams = await this.$store.dispatch(
        "animalsModule/fetchAnimalExams",
        id
      );
      this.selectedAnimal.exams = [];
      this.$forceUpdate();
      // exams = exams.splice(0, 5);
      this.selectedAnimal.exams = exams;
      this.$set(this.selectedAnimal, "exams", exams);
      this.selectedAnimal = this.selectedAnimal;
      this.$forceUpdate();
    },
  },
  setup() {
    const isAddNewAnimalSidebarActive = ref(false);
    const isAddNewAnimalMilkingSidebarActive = ref(false);
    const isEditAnimalMilkingSidebarActive = ref(false);

    const {
      fetchAnimals,
      tableColumns,
      perPage,
      currentPage,
      totalAnimals,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAnimalListTable,
      refetchData,
      deleteAnimal,
      animals,
      // UI
      perPageOptions,

      // Extra Filters
      sourceFilter,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      barnFilter,
    } = useAnimalList();

    const filterCount = computed(() => {
      const moreThanZero = (val) => (val > 0 ? 1 : 0);
      return (
        moreThanZero(sourceFilter.value) +
        moreThanZero(typeFilter.value) +
        moreThanZero(genderFilter.value) +
        moreThanZero(statusFilter.value) +
        moreThanZero(groupFilter.value) +
        moreThanZero(barnFilter.value)
      );
    });
    return {
      filterCount,
      // Sidebar
      isAddNewAnimalSidebarActive,
      isAddNewAnimalMilkingSidebarActive,
      isEditAnimalMilkingSidebarActive,
      fetchAnimals,
      tableColumns,
      perPage,
      currentPage,
      totalAnimals,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAnimalListTable,
      refetchData,
      perPageOptions,
      deleteAnimal,
      animals,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      sourceFilter,
      typeFilter,
      genderFilter,
      statusFilter,
      groupFilter,
      barnFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
.rowClass {
  cursor: pointer !important;
}
.table tbody tr td .card {
  cursor: default;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216);
}
.animal-table-col {
  min-width: 100%;
  max-width: 100%;
  @media (min-width: 992px) {
    min-width: 270px;
    max-width: 270px;
  }
}
.slot {
  max-width: 100%;

  overflow: hidden;
  @media (min-width: 992px) {
    width: auto;
  }
}
</style>
