<template>
  <b-row class="w-100 flex-grow-1 animal-detail-info">
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <h3>{{ animal.earingno }}</h3>
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <animal-info
        :label="$t('birthdate')"
        :value="
          dayjs(animal.birthdate)
            .locale(`${this.$i18n.locale}`)
            .format('DD MMMM YYYY')
        "
      />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <!-- GroupName -->
      <animal-info :label="$t('groupName')" :value="animal.groupName" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <!-- GroupName -->
      <animal-info :label="$t('padok')" :value="animal.padokname" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <!-- RFID -->
      <animal-info
        :label="$t('rfid')"
        :value="animal.rfidno ? animal.rfidno : '-'"
      />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <!-- Gender -->
      <animal-info :label="$t('gender')" :value="animal.gendername" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <!-- AnimalType -->
      <animal-info :label="$t('animalType')" :value="animal.animalType" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <animal-info :value="animal.animalName" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <animal-info :label="$t('race')" :value="animal.breedname" />
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="4" xl="4">
      <animal-info :label="$t('animalStatus')" :value="animal.statusname" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import AnimalInfo from "./AnimalInfo.vue";

import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  components: {
    BRow,
    BCol,
    AnimalInfo,
  },

  data() {
    return {
      dayjs,
      loadingDots: "",
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal,
      loading: (state) => state.animalsModule.loading,
    }),
  },
  watch: {
    loadingDots: function (val) {
      var dotCount = this.loadingDots.length;
      const timeOut = setTimeout(() => {
        if (dotCount < 3) {
          this.loadingDots += ".";
        } else {
          this.loadingDots = ".";
        }
      }, 1000);
      if (!this.loading) {
        clearTimeout(timeOut);
      }
    },
    animal: {
      handler: function (val) {
        this.$emit("animalChanged", val);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadingDots = this.loading ? "." : "";
  },
};
</script>

<style>
.animal-detail-info {
  padding: 1.5rem;
}
</style>
