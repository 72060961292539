<template>
  <div class="d-flex align-items-center">
    <span v-if="label" style="font-size: 1.1rem" class="font-weight-bold">
      {{ label }}:
    </span>
    <span
      :style="
        !label ? 'font-size: 1.1rem' : 'font-size: 1rem;margin-left: 4px;'
      "
    >
      {{ value }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
