<template>
  <b-modal
    id="modal-code"
    ref="modalRef"
    centered
    ok-title="Ok"
    ok-only
    :title="$t('addGroup')"
    @ok="addGroup"
  >
    <b-form>
      <b-form-group>
        <span>{{ $t("groupAdded") }}</span>
        <br />
        <label for="name">{{ $t("name") }}:</label>
        <b-form-input id="name" v-model="groupname" placeholder="" />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {},
  data: function () {
    return {
      groupname: "",
    };
  },
  methods: {
    async addGroup() {
      this.$store
        .dispatch("animalsModule/addGroup", {
          id: 0,
          companyid: getUserData().companyid,
          plantid: this.$store.state.app.selectedPlantId,
          groupname: this.groupname,
        })
        .then((val) => {
          if (val != false) {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-group-sidebar-active", false);
          }
        });
      this.$emit("fetchGroup");
    },
    async openModal() {
      this.$refs["modalRef"].show();
    },
  },
};
</script>

<style></style>
