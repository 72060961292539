<template>
  <div>
    <b-modal
      id="edit-animal-sidebar"
      centered
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('editAnimal')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankTinyBirth)"
      @hidden="resetForm"
      @change="changeCurrent"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <validation-provider
            #default="validationContext"
            name="mother"
            rules="required"
          >
            <b-form-group :label="$t('mother')" label-for="mother">
              <v-select
                v-model="blankTinyBirth.motherid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animalsEar"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="kulakNo"
            rules="required"
          >
            <b-form-group :label="$t('earingNo')" label-for="kulakno">
              <b-form-input
                id="kulakno"
                v-model="blankTinyBirth.earingno"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- animal type -->

          <validation-provider
            #default="validationContext"
            name="animalType"
            rules="required"
          >
            <b-form-group :label="$t('animalType')" label-for="animalType">
              <v-select
                id="animalType"
                v-model="blankTinyBirth.animaltypeid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animalTypes"
                class="w-100"
                :reduce="(val) => val.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="animalSource"
            rules="required"
          >
            <b-form-group :label="$t('animalSource')" label-for="animalSource">
              <v-select
                id="animalSource"
                v-model="blankTinyBirth.sourceid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animalSources"
                class="w-100"
                :reduce="(val) => val.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- animal status -->

          <validation-provider
            #default="validationContext"
            name="animalStatus"
            rules="required"
          >
            <b-form-group :label="$t('animalStatus')" label-for="animalStatus">
              <v-select
                id="animalStatus"
                v-model="blankTinyBirth.statusid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animalStatus"
                class="w-100"
                :reduce="(val) => val.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- animal group -->

          <validation-provider
            #default="validationContext"
            name="animalGroups"
            rules="required"
          >
            <b-form-group :label="$t('animalGroup')" label-for="animalGroups">
              <b-row>
                <b-col cols="10">
                  <v-select
                    id="animalGroups"
                    v-model="blankTinyBirth.groupid"
                    transition=""
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="animalGroups"
                    class="w-100"
                    :reduce="(val) => val.value"
                  />
                </b-col>
                <b-col cols="1" class="mr-1">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="h-full h-100 justify-content-center align-items-center cursor-pointer"
                    @click="$refs.groupModal.openModal()"
                  >
                    <feather-icon
                      class="cursor-pointerr"
                      icon="PlusIcon"
                      size="16"
                    />
                  </b-button>
                </b-col>
              </b-row>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- barns -->

          <validation-provider
            #default="validationContext"
            name="barns"
            rules="required"
          >
            <b-form-group :label="$t('barn')" label-for="barns">
              <b-row>
                <b-col cols="12">
                  <v-select
                    id="barns"
                    v-model="blankTinyBirth.padokid"
                    transition=""
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="barns"
                    class="w-100"
                    :reduce="(val) => val.value"
                  />
                </b-col>
              </b-row>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider #default="validationContext" name="ad">
            <b-form-group :label="$t('animalName')" label-for="ad">
              <b-form-input
                id="ad"
                v-model="blankTinyBirth.animalName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dogumtarihi -->
          <validation-provider
            #default="validationContext"
            name="dogumtarihi"
            rules="required"
          >
            <b-form-group :label="$t('animalBirthDay')" label-for="dogumtarihi">
              <b-form-datepicker
                v-model="blankTinyBirth.birthdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- ırk -->
          <validation-provider
            #default="validationContext"
            name="irk"
            rules="required"
          >
            <b-form-group :label="$t('race')" label-for="irk">
              <v-select
                v-model="blankTinyBirth.breedid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="animalBreeds"
                class="w-100"
                :reduce="(val) => val.value"
                @input="(val) => $emit('update:typeFilter', val)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cinsiyeert -->
          <validation-provider
            #default="validationContext"
            name="cinsiyet"
            rules="required"
          >
            <b-form-group :label="$t('gender')" label-for="cinsiyet">
              <v-select
                v-model="blankTinyBirth.genderid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                class="w-100"
                :reduce="(val) => val.value"
                @input="(val) => $emit('update:typeFilter', val)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- lactation -->
          <validation-provider #default="validationContext" name="lactation">
            <b-form-group :label="$t('lactation')" label-for="lactation">
              <b-form-input
                id="lactation"
                v-model="blankTinyBirth.lactation"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <!-- <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div> -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, computed, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
    animalSources: {
      type: Array,
      required: true,
    },
    animalBreeds: {
      type: Array,
      required: true,
    },
    animalStatus: {
      type: Array,
      required: true,
    },

    genderOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  async created() {
    // var { data } = await this.$http.get("Animals/GetBreeds");
    // data.map((x) =>
    //   this.animaltypeOptions.push({ label: x.breedname, value: x.breedid })
    // );
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);

    const blankTinyBirthData = {
      id: props.selectedAnimal.id,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      earingno: props.selectedAnimal.earingno,
      animalName: props.selectedAnimal.animalName,
      animaltype: props.selectedAnimal.animaltype,
      animaltypeid: props.selectedAnimal.animalTypeid,
      birthdate: props.selectedAnimal.birthdate,
      groupid: props.selectedAnimal.groupid,
      breed: props.selectedAnimal.breed,
      breedid: props.selectedAnimal.breedid,
      gender: props.selectedAnimal.gender,
      genderid: props.selectedAnimal.genderid,
      status: props.selectedAnimal.status,
      statusid: props.selectedAnimal.statusid,
      statusdate: props.selectedAnimal.statusdate,
      datein: props.selectedAnimal.datein,
      dateout: props.selectedAnimal.dateout,
      source: props.selectedAnimal.source,
      sourceid: props.selectedAnimal.sourceid,
      lactation: props.selectedAnimal.lactation,
      motherid: props.selectedAnimal.motherid,
      childrencount: props.selectedAnimal.childrencount,
      padokid: props.selectedAnimal.padokid,
    };

    let animalGroups = ref([]);
    let barns = ref([]);
    const fetchGroup = async () => {
      animalGroups.value = [];
      var data = await store.dispatch("animalsModule/fetchGroups");
      emit("refetch-group");
      data.map((x) =>
        animalGroups.value.push({ label: x.groupname, value: x.id })
      );
    };
    fetchGroup();
    const fetchBarns = async () => {
      barns.value = [];
      var data = await store.dispatch("definitionsModule/fetchPadoks", {
        plantid: store.state.app.selectedPlantId,
        barnid: -1,
      });
      emit("refetch-barn");
      data.map((x) => barns.value.push({ label: x.padokname, value: x.id }));
    };
    fetchBarns();

    const blankTinyBirth = ref(JSON.parse(JSON.stringify(blankTinyBirthData)));
    const resetblankTinyBirth = () => {
      blankTinyBirth.value = JSON.parse(JSON.stringify(blankTinyBirthData));
    };
    const changeCurrent = (val) => {
      blankTinyBirth.value = {
        id: props.selectedAnimal.id,
        companyid: getUserData().companyid,
        plantid: store.state.app.selectedPlantId,
        earingno: props.selectedAnimal.earingno,
        animalName: props.selectedAnimal.animalName,
        animaltype: props.selectedAnimal.animaltype,
        animaltypeid: props.selectedAnimal.animalTypeid,
        birthdate: props.selectedAnimal.birthdate,
        groupid: props.selectedAnimal.groupid,
        breed: props.selectedAnimal.breed,
        breedid: props.selectedAnimal.breedid,
        gender: props.selectedAnimal.gender,
        genderid: props.selectedAnimal.genderid,
        status: props.selectedAnimal.status,
        statusid: props.selectedAnimal.statusid,
        statusdate: props.selectedAnimal.statusdate,
        datein: props.selectedAnimal.datein,
        dateout: props.selectedAnimal.dateout,
        source: props.selectedAnimal.source,
        sourceid: props.selectedAnimal.sourceid,
        lactation: props.selectedAnimal.lactation,
        motherid: props.selectedAnimal.motherid,
        childrencount: props.selectedAnimal.childrencount,
        padokid: props.selectedAnimal.padokid,
      };
      store.commit("animalsModule/SET_EDIT_SIDEBAR", val);
    };
    const onSubmit = (blankAnimalData) => {
      store
        .dispatch("animalsModule/updateAnimal", blankAnimalData)
        .then((res) => {
          if (res.data.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.data.statusdata == "0") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalUpdated"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            store.commit("animalsModule/SET_EDIT_SIDEBAR", false);
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalNotUpdated"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
    };
    var animalsEar = computed(() => [
      { label: "Yok", value: -1 },
      ...store.state.animalsModule.animalsEar,
    ]);
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankTinyBirth);
    return {
      blankTinyBirth,
      onSubmit,
      animalGroups,
      refFormObserver,
      getValidationState,
      resetForm,
      animalsEar,
      fetchGroup,
      barns,
      changeCurrent,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
