<template>
  <div>
    <animal-list>
      <template #animal-detail>
        <animal-detail />
      </template>
      <div class="d-flex flex-grow-1">
        <layout-nested />
      </div>
    </animal-list>
  </div>
</template>

<script>
import AnimalList from "./components/AnimalList/AnimalList.vue";
import AnimalDetail from "./components/AnimalDetail.vue";
import LayoutNested from "@/layouts/nested/LayoutNested.vue";
export default {
  components: { AnimalList, AnimalDetail, LayoutNested },
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
};
</script>

<style></style>
